// Generated by Framer (1c1e241)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import IconExpand from "https://framerusercontent.com/modules/jKFdsm7bqt0u9yGzCbE2/VJRER2W76DpBxTMCgelQ/uMii_qG44.js";
const IconExpandFonts = getFonts(IconExpand);

const cycleOrder = ["FemQfiMZs", "SkcPwb5K0"];

const serializationHash = "framer-dk7nR"

const variantClassNames = {FemQfiMZs: "framer-v-svp9es", SkcPwb5K0: "framer-v-1o44kz2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Desktop: "FemQfiMZs", Mobile: "SkcPwb5K0"}

const getProps = ({height, id, tap, title, width, ...props}) => { return {...props, NXouvtSa4: tap ?? props.NXouvtSa4, OKcxSbuUP: title ?? props.OKcxSbuUP ?? "Getting started ", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FemQfiMZs"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NXouvtSa4, OKcxSbuUP, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "FemQfiMZs", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const fHoEZHX1cfcvpvo = activeVariantCallback(async (...args) => {
if (NXouvtSa4) {
const res = await NXouvtSa4(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "SkcPwb5K0") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-dk7nR", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-svp9es", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"FemQfiMZs"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-fd2a9202-3b01-4729-831f-86b0b036c94c, rgb(17, 17, 17))", ...style}} {...addPropertyOverrides({SkcPwb5K0: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-y7mneh-container"} layoutDependency={layoutDependency} layoutId={"Dmj4YYOv3-container"}><IconExpand fHoEZHX1c={fHoEZHX1cfcvpvo} height={"100%"} id={"Dmj4YYOv3"} layoutId={"Dmj4YYOv3"} style={{height: "100%", width: "100%"}} variant={"CZYjpIXhD"} width={"100%"}/></motion.div>)}<motion.div className={"framer-16i25hj"} data-framer-name={"Guides Title"} layoutDependency={layoutDependency} layoutId={"ituAltJD4"} style={{backgroundColor: "var(--token-fd2a9202-3b01-4729-831f-86b0b036c94c, rgb(17, 17, 17))"}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "Q1VTVE9NO1BvbHlTYW5zIFRyaWFsIE5ldXRyYWw=", "--framer-font-family": "\"PolySans Trial Neutral\", \"PolySans Trial Neutral Placeholder\", sans-serif", "--framer-font-size": "32px", "--framer-letter-spacing": "-0.5px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-1of0zx5, var(--token-600033ff-fcdd-4361-a34f-2d51d675bcd8, rgb(255, 255, 255)))"}}>Getting started </motion.h2></React.Fragment>} className={"framer-17hvqdt"} data-framer-name={"Blitz for life"} fonts={["CUSTOM;PolySans Trial Neutral"]} layoutDependency={layoutDependency} layoutId={"aeb9xEeU1"} style={{"--extracted-1of0zx5": "var(--token-600033ff-fcdd-4361-a34f-2d51d675bcd8, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={OKcxSbuUP} verticalAlignment={"center"} withExternalLayout/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-dk7nR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dk7nR .framer-8r5x8o { display: block; }", ".framer-dk7nR .framer-svp9es { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 70px 0px 20px 0px; position: relative; width: 916px; }", ".framer-dk7nR .framer-y7mneh-container { flex: none; height: 44px; position: relative; width: 44px; }", ".framer-dk7nR .framer-16i25hj { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1px; z-index: 1; }", ".framer-dk7nR .framer-17hvqdt { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dk7nR .framer-svp9es { gap: 0px; } .framer-dk7nR .framer-svp9es > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-dk7nR .framer-svp9es > :first-child { margin-left: 0px; } .framer-dk7nR .framer-svp9es > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 135
 * @framerIntrinsicWidth 916
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"SkcPwb5K0":{"layout":["fixed","auto"]}}}
 * @framerVariables {"NXouvtSa4":"tap","OKcxSbuUP":"title"}
 * @framerImmutableVariables true
 */
const FramerVP_8t6dcv: React.ComponentType<Props> = withCSS(Component, css, "framer-dk7nR") as typeof Component;
export default FramerVP_8t6dcv;

FramerVP_8t6dcv.displayName = "Guides Title";

FramerVP_8t6dcv.defaultProps = {height: 135, width: 916};

addPropertyControls(FramerVP_8t6dcv, {variant: {options: ["FemQfiMZs", "SkcPwb5K0"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}, NXouvtSa4: {title: "Tap", type: ControlType.EventHandler}, OKcxSbuUP: {defaultValue: "Getting started ", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerVP_8t6dcv, [{family: "PolySans Trial Neutral", url: "https://framerusercontent.com/assets/WePbG2Eeuj3YYKrMeNk7EUb3LOA.otf"}, ...IconExpandFonts])